<template lang="pug">
Popup
    template(slot="title")
      template(v-if="isEdit")  {{ $t('Yield.yield_detail_form_edit_title') }}
      template(v-else) {{ $t('Yield.yield_detail_form_title') }}
    template(slot="content")
      form.Form(
        id="yield-detail-form",
        @submit.prevent="onSubmitForm"
      )

        .Form-item.required
          label.Form-item-label {{ $t('Yield.Form_StockItemName') }}
          .control
            custom-search(
              name="stockItem"
              component-name="form-yield-detail-stock-item"
              v-model="form.stockItem"
              :data-vv-as="$t('Yield.Form_StockItemName')"
              v-validate="validateRules"
              :disabled="isEdit"
              ref="focusableInput"
              :inputClass="{ 'is-danger': veeErrors.has('stockItem') }"
              :on-search="getOptions"
              :options="selectOptions"
              label="name"
              @input="setKgQuantity"
              :placeholder="$t('Yield.Form_StockItemName_Placeholder')"
              :directSelectOption="directSelectOption"
            )

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('stockItem')"
          ) {{ veeErrors.first('stockItem') }}

        .Form-item.required
          label.Form-item-label
            | {{ $t('Yield.Form_BaseUnit') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")
            select.select(
              id="select-request-popup-baseunit"
              name="unitId"
              :disabled="isEdit || !form.stockItem"
              v-model="form.unitId"
              v-validate="'required|is_not:-1'"
              :data-vv-as="$t('Yield.Form_BaseUnit')"
              :class="{ 'is-danger': veeErrors.has('unitId') }")
              option(
                v-if="isEdit"
                value="-1"
                id="option-request-popup-baseunit-unselected") {{ $t('Request.Form_BaseUnit_Unselected') }}
              option(
                v-for="unit in filteredUnitList"
                :key="unit.id"
                :value="unit.id"
                :id="`option-request-popup-baseunit-${unit.name}`"
              ) {{ unit.name }}
          .flexbox.m-top-10.font-size-small(v-if="form.stockItem")
            p.c-success.font-weight-bold {{ $t('Yield.Form_Label_KgQuantity') }}
            p.m-left-10.font-weight-normal.c-dark {{ kgQuantity }}

          p.Form-item-help.is-danger(
            v-show="veeErrors.has('unitId')"
          ) {{ veeErrors.first('unitId') }}

        .Form-item.required
          label.Form-item-label {{ $t('Dictionary.Quantity') }}
          .control
            input.txt.no-spinners(
              name="quantity"
              id="yield-detail-form-quantity"
              autocomplete="off"
              ref="quantityInput"
              :data-vv-as="$t('Dictionary.Quantity')"
              v-model.number="form.quantity"
              v-validate="validateQuantityRules",
              :class="{ 'is-danger': veeErrors.has('quantity') }"
              type="number"
              step="any")
          p.Form-item-help.is-danger(
            v-show="veeErrors.has('quantity')"
          ) {{ veeErrors.first('quantity') }}
        .flexbox.m-top-10.font-size-small
          .flexbox(v-if="kgQuantity && form.quantity")
            p.c-success.font-weight-bold {{ $t('Yield.Form_Label_KgConvertion') }}
            p.m-left-10.font-weight-normal.c-dark {{ kgQuantity * form.quantity * getfilteredUnitListItem.baseQuantity }}
          .flexbox.m-left-10(v-if="form.stockItem")
            p.c-success.font-weight-bold {{ $t('Yield.StockOnHand') }}
            p.font-weight-normal.c-dark.m-left-10 {{ form.stockOnHandQuantity || stockItemInfo.baseUnitQuantity }}

        .Form-item.required.m-top-15(
          id="popup-form-min-opt-item"
        )
          label.Form-item-label
            span {{ $t('Yield.header-popup-cost-selection') }}
          .control.flexbox.wrap.m-top-10
            .cost-selection-radio
              CustomRadio(
                :id="`yield-header-popup-calculate-by-main-product`"
                :label="$t('Yield.header-popup-calculate-by-main-product')"
                :checked="form.pricingType === 1"
                @change="changePriceType(1)")
            .cost-selection-radio
              CustomRadio.m-top-10(
                :id="`yield-header-popup-use-last-purchase-price`"
                :label="$t('Yield.header-popup-use-last-purchase-price')"
                :checked="form.pricingType === 2"
                @change="changePriceType(2)")

              customNumberInput.last-purchase-price(
                name="lastPurchasePrice"
                id="yield-detail-form-last-purchase-price"
                v-model="form.lastPurchasePrice"
                :data-vv-as="$t('Yield.detail-popup-last-purchase-price')"
                v-validate="{required: form.pricingType === 2, min_value: 0}",
                :class="{ 'is-danger': veeErrors.has('lastPurchasePrice') }"

                :disabled="form.pricingType !== 2"
              )

            showValidateError(
              v-show="veeErrors.has('lastPurchasePrice')"
              :errorName="veeErrors.first('lastPurchasePrice')"
            )

            .cost-selection-radio
              CustomRadio(
                :id="`yield-header-popup-calculate-by-main-product-percent`"
                :label="$t('Yield.header-popup-calculate-by-main-product-percent')"
                :checked="form.pricingType === 3"
                @change="changePriceType(3)")

              customNumberInput(
                name="percent"
                id="yield-detail-form-calculate-percent"
                v-model="form.pricingPercent"
                :data-vv-as="$t('Yield.detail-popup-calculate-by-main-product-percent')"
                v-validate="{required: form.pricingType === 3, min_value: 0}",
                :class="{ 'is-danger': veeErrors.has('percent') }"
                :disabled="form.pricingType !== 3"
                :isTypePercentage="true"
                placeholder=",00"
              )

            showValidateError(
              v-show="veeErrors.has('percent')"
              :errorName="veeErrors.first('percent')"
            )

    template(slot="footer")
      div.add-other-items-checkbox-container
        CustomCheckbox(
          v-if="!isEdit"
          id="checkbox-inventory-header-save-and-new"
          :label="$t('Global.formSaveAndNewText', { form: $t('Global.detail') })"
          v-model="saveAndNew")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        id="button-yield-add-detail",
        form="yield-detail-form",
        :disabled="isCreating",
      )
        span(v-show="!isCreating")
          template(v-if="isEdit") {{ $t('Global.editData', { field: $t('Yield.Add_Yield_Detail') }) }}
          template(v-else) {{ $t('Global.addData', { field: $t('Yield.Add_Yield_Detail') }) }}
        Loading(theme="disable", v-show="isCreating")
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import { getObjectValuesByKey, vueWaitLoader } from '@/utils/baseOperations'
import moment from 'moment'
import { Validator } from 'vee-validate'

export default {
  name: 'yield-detail-form',
  props: {
    status: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      form: {
        stockItem: null,
        unitId: '',
        quantity: null,
        pricingPercent: null,
        lastPurchasePrice: null,
        pricingType: 1 
      },
      selectOptions: [],
      stockItem: {},
      saveAndNew: true,
      kgQuantity: 0,
      totalDetailKgQuantity: 0,
      kgQuantityLimit: 0,
      firstKgQuantity: 0,
      storeId: null,
      stockItemInfo: {}
    }
  },

  mounted () {
    this.setDetailForm()
    this.setHeaderQuantityLimit()
    this.setValidation()
  },

  computed: {
    ...mapGetters('Units', [
      'findUnit',
      'getRelatedUnitList'
    ]),
    ...mapGetters('i18n', ['langDefault']),

    directSelectOption () {
      return this.selectOptions.length === 1 &&
        this.selectOptions[0].matchType === 2
        ? this.selectOptions[0]
        : {}
    },

    filteredUnitList () {
      if (!this.form.stockItem) return []
      const baseUnitId = this.isEdit
        ? this.findUnit(this.form.unitId).baseUnitId
        : this.findUnit(this.form.stockItem.unitId).baseUnitId
      return this.getRelatedUnitList(baseUnitId)
    },

    isEdit () {
      return this.status === 'edit'
    },

    isCreating () {
      return this.$wait.is(['createYieldDetail', 'updateYieldDetail', 'onSubmitFormYieldDetail'])
    },

    validateKgQuantityLimit () {
      return this.calculateTotalKqQuantity + (this.totalDetailKgQuantity - this.firstKgQuantity) <= this.kgQuantityLimit
    },

    calculateTotalKqQuantity () {
      return this.form.quantity * this.kgQuantity * this.getfilteredUnitListItem.baseQuantity
    },

    validateRules () {
      const url = `Yield/detail/check/stockitem`
      return this.form.stockItem
        ? `required|very_singularity:${url}?YieldHeaderId=${this.$route.params.headerId}&StockItemId=${this.form.stockItem.id}`
        : `required`
    },

    validateQuantityRules () {
      return `required|greater_than:0|decimal:6|quantityLimitCheck:${this.validateKgQuantityLimit}|max:${this.$getConst('MAX_DIGITS_FOR_QUANTITY')}`
    },

    getfilteredUnitListItem () {
      return this.findUnit(this.form.unitId) || 0
    }
  },
  methods: {
    ...mapActions('Stock', ['searchStockItem', 'getStockItem']),

    ...mapActions('Yield', ['createYieldDetail', 'updateYieldDetail', 'fetchYieldDetailAll', 'fetchYieldDetail', 'fetchYield']),

    ...mapActions('Notify', ['notifyShow']),

    ...mapMutations('Yield', ['SHOW_DETAIL']),

    ...mapActions('Transfer', ['getStockInfo']),

    getOptions (search) {
      this.searchStockItem({Text: search, types: [1, 2, 3]})
        .then(res => {
          this.selectOptions = res.data.items
          this.stockItem = res.data.items
        })
    },

    async fetchStockItems (item) {
      await this.fetchYield(this.$route.params.headerId)
        .then(result => {
          this.storeId = result.data.yieldHeader.storeId
        })
      const payload = {
        storeId: this.storeId,
        stockItemId: this.form.stockItem.id || item.id,
        unitId: item.baseUnitId,
        forDate: moment(new Date()).format('YYYY-MM-DD')
      }
      await this.getStockInfo(payload).then(res => {
        this.stockItemInfo = res.data
      })
    },

    setDetailForm () {
      if (this.isEdit) {
        this.$refs.quantityInput.focus()
        if (this.$route.params.item) this.setForm(this.$route.params.item)
        else {
          this.fetchYieldDetail(this.$route.params.id)
            .then(result => {
              this.setForm(result.data.yieldDetail)
            })
        }
      } else {
        this.$refs.focusableInput.$refs.search.focus()
      }
    },

    setHeaderQuantityLimit () {
      this.fetchYield(this.$route.params.headerId)
        .then(result => {
          this.kgQuantityLimit = result.data.yieldHeader.kgQuantity
          this.totalDetailKgQuantity = result.data.yieldHeader.totalDetailKgQuantity
        })
    },

    setForm (item) {
      this.form = {...item}
      this.form.stockItem = {
        id: item.stockItemId,
        name: item.stockItemName
      }
      this.firstKgQuantity = this.form.kgQuantity || 0
      this.setKgQuantity()
    },

    setKgQuantity (item = null) {
      this.getStockItem(this.form.stockItem.id)
        .then(res => {
          this.form.stockItem = res.data.stockItem
          if (this.form.pricingType === 2) this.form.lastPurchasePrice = this.form.stockItem.lastPurchasePrice
          this.fetchStockItems(res.data.stockItem)
          if (!this.isEdit) this.kgQuantity = item.kgQuantity
          else {
            this.kgQuantity = res.data.stockItem.kgQuantity
          }
        })
    },

    setValidation () {
      Validator.extend('quantityLimitCheck', (value, [otherValue]) => {
        return {
          valid: otherValue === 'true'
        }
      }, {
        computesRequired: true
      })
      const quantityLimitCheckMessages = {
        [this.langDefault]: {
          messages: {
            quantityLimitCheck: field => this.$t('Yield.quantityLimitCheckMessages')
          }
        }
      }
      Validator.localize(quantityLimitCheckMessages)
    },

    formReset () {
      this.form.stockItem = null
      this.form.unitId = ''
      this.form.quantity = null
      this.form.pricingPercent = null
      this.form.lastPurchasePrice = null
      this.form.pricingType = 1
    },

    setUnit (unitId) {
      this.form.unitId = unitId
    },

    changePriceType (type) {
      this.form.pricingType = type
      if (type === 2) this.form.lastPurchasePrice = this.form.stockItem.lastPurchasePrice
      else this.form.lastPurchasePrice = null
      if (type !== 3) this.form.pricingPercent = null
    },

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async result => {
        if (!result) return
        if (this.isEdit) await this.editDetail()
        else await this.newDetail()
      })
    }, 'onSubmitFormYieldDetail'),

    async newDetail () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'unitId', 'pricingType'])
      payload.yieldHeaderId = this.$route.params.headerId
      payload.stockItemId = this.form.stockItem.id
      this.form.pricingType === 2 ? payload.lastPurchasePrice = this.form.lastPurchasePrice : payload.lastPurchasePrice = null
      this.form.pricingType === 3 ? payload.pricingPercent = this.form.pricingPercent : payload.pricingPercent = null
      await this.createYieldDetail(payload)
        .then(async res => {
          if (!res) {
            this.$wait.end('submit')
            return
          }
          if (this.saveAndNew) {
            this.formReset()
            this.$nextTick(() => this.$refs.focusableInput.$refs.search.focus())
            this.$validator.reset()
            await this.fetchDataAfterSubmit(false)
          } else {
            await this.fetchDataAfterSubmit()
          }
        })
    },

    async editDetail () {
      const payload = getObjectValuesByKey(this.form, ['quantity', 'id', 'pricingType'])
      this.form.pricingType === 2 ? payload.lastPurchasePrice = this.form.lastPurchasePrice : payload.lastPurchasePrice = null
      this.form.pricingType === 3 ? payload.pricingPercent = this.form.pricingPercent : payload.pricingPercent = null
      await this.updateYieldDetail(payload)
        .then(async res => {
          if (!res) return
          await this.fetchDataAfterSubmit()
        })
    },

    async fetchDataAfterSubmit (isClosing = true) {
      await this.fetchYieldDetailAll(this.$route.params.headerId)
        .then(result => {
          if (!result) return
          let message = ''
          this.isEdit ? message = this.$t('Yield.editDetailSuccessMessage') : message = this.$t('Yield.createDetailSuccessMessage')
          this.notifyShow({ message })
          this.fetchYield(this.$route.params.headerId)
          if (isClosing) this.closePopup()
        })
    }
  },
  watch: {
    async 'form.stockItem' (val) {
      if (!val || this.isEdit) return
      await this.setUnit(val.unitId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .last-purchase-price{
    margin-left: 40px !important;
  }
  .cost-selection-radio {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .txt {
      justify-self: flex-end;
      height: 40px;
      min-height: unset !important;
      width: 150px;
      margin-left: 15px;
      margin-top: 10px;
    }
  }
</style>
