import BaseClass from '@/utils/baseclass'

export default class Yields extends BaseClass {
  addItem(item) {
    let newItem = new YieldHeader(item)
    this.list.push(newItem)
  }

  updateItem(item) {
    let findItem = this.findItem(item.id)
    if (findItem) {
      findItem.id = item.id
      findItem.wasteQuantity = item.wasteQuantity
      findItem.wastePercent = item.wastePercent
    }
  }
}

export class YieldHeader {
  constructor(item) {
    this.code = item.code
    this.description = item.description
    this.id = item.id
    this.quantity = item.quantity
    this.status = item.status
    this.storeId = item.storeId
    this.storeName = item.storeName
    this.unitId = item.unitId
    this.unitName = item.unitName
    this.wasteGroupId = item.wasteGroupId
    this.wasteGroupName = item.wasteGroupName
    this.wasteHeaderId = item.wasteHeaderId
    this.yieldDate = item.yieldDate
    this.yieldStockItemId = item.yieldStockItemId
    this.yieldStockItemName = item.yieldStockItemName
    this.kgQuantity = item.kgQuantity
    this.yieldStockItemKgQuantity = item.yieldStockItemKgQuantity
    this.pricingType = item.pricingType
    this.despatchHeaderId = item.despatchHeaderId
    this.invoiceId = item.invoiceId
    this.price = item.price
    this.totalPrice = item.totalPrice
    this.wasteQuantity = item.wasteQuantity
    this.wastePercent = item.wastePercent
    this.despatchNumber = item.despatchNumber
    this.invoiceNumber = item.invoiceNumber
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
    this.totalDetailKgQuantity = item.totalDetailKgQuantity
    this.showDetail = false
    this.detailList = []
    this.stockOnHandQuantity = item.stockOnHandQuantity
  }

  addDetails(list) {
    this.detailList = []
    list.forEach(detail => {
      this.detailList.push(new YieldDetail(detail))
    })
  }
}

export class YieldDetail {
  constructor(item, storeId) {
    this.id = item.id
    this.yieldHeaderId = item.yieldHeaderId
    this.stockItemId = item.stockItemId
    this.stockItemName = item.stockItemName
    this.unitId = item.unitId
    this.unitName = item.unitName
    this.quantity = item.quantity
    this.pricingType = item.pricingType
    this.stockItemKgQuantity = item.stockItemKgQuantity
    this.kgQuantity = item.kgQuantity
    this.pricingPercent = item.pricingPercent
    this.lastPurchasePrice = item.lastPurchasePrice
    this.kgQuantityPercent = item.kgQuantityPercent
    this.expirationDateControlIsEnabled = item.expirationDateControlIsEnabled
    this.price = item.price
    this.totalPrice = item.totalPrice
    this.stockOnHandQuantity = item.stockOnHandQuantity
    this.storeId = storeId
  }
}
