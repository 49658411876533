<template lang="pug">
DeleteAlert(
    :item="item",
    :fields="fields",
    :tableName="$t('Yield.yieldHeaderRemoveFormTitle')",
    loading="deleteYieldHeader",
    @delegateOnAlertRemoveOk="itemRemove")
</template>

<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'yield-header-delete',

  data () {
    return {
      fields: [{
        name: 'code',
        is_visible: true,
        short_title: this.$t('Yield.table_Header_field_RNumber'),
        title: this.$t('Yield.table_Header_field_RNumber')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    }
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Yield', [
      'deleteYieldHeader'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      let result = await this.deleteYieldHeader(this.item.id)
      if (result) {
        const message = this.$t('Yield.removeYieldHeader_Notificiation_Deleted')
        this.notifyShow({ message })
        await this.$emit('getYieldHeaderList')
        this.close()
      }
    }, 'itemRemoveYieldHeader')
  }
}
</script>

<style lang="scss">
</style>
