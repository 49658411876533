var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  " " + _vm._s(_vm.$t("Yield.yield_detail_form_edit_title"))
                ),
              ]
            : [_vm._v(_vm._s(_vm.$t("Yield.yield_detail_form_title")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "yield-detail-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Yield.Form_StockItemName"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validateRules,
                        expression: "validateRules",
                      },
                    ],
                    ref: "focusableInput",
                    attrs: {
                      name: "stockItem",
                      "component-name": "form-yield-detail-stock-item",
                      "data-vv-as": _vm.$t("Yield.Form_StockItemName"),
                      disabled: _vm.isEdit,
                      inputClass: {
                        "is-danger": _vm.veeErrors.has("stockItem"),
                      },
                      "on-search": _vm.getOptions,
                      options: _vm.selectOptions,
                      label: "name",
                      placeholder: _vm.$t(
                        "Yield.Form_StockItemName_Placeholder"
                      ),
                      directSelectOption: _vm.directSelectOption,
                    },
                    on: { input: _vm.setKgQuantity },
                    model: {
                      value: _vm.form.stockItem,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stockItem", $$v)
                      },
                      expression: "form.stockItem",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("stockItem"),
                      expression: "veeErrors.has('stockItem')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("stockItem")))]
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Yield.Form_BaseUnit"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.unitId,
                          expression: "form.unitId",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|is_not:-1",
                          expression: "'required|is_not:-1'",
                        },
                      ],
                      staticClass: "select",
                      class: { "is-danger": _vm.veeErrors.has("unitId") },
                      attrs: {
                        id: "select-request-popup-baseunit",
                        name: "unitId",
                        disabled: _vm.isEdit || !_vm.form.stockItem,
                        "data-vv-as": _vm.$t("Yield.Form_BaseUnit"),
                      },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "unitId",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _vm.isEdit
                        ? _c(
                            "option",
                            {
                              attrs: {
                                value: "-1",
                                id: "option-request-popup-baseunit-unselected",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("Request.Form_BaseUnit_Unselected")
                                )
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._l(_vm.filteredUnitList, function (unit) {
                        return _c(
                          "option",
                          {
                            key: unit.id,
                            attrs: {
                              id: `option-request-popup-baseunit-${unit.name}`,
                            },
                            domProps: { value: unit.id },
                          },
                          [_vm._v(_vm._s(unit.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.form.stockItem
                ? _c(
                    "div",
                    { staticClass: "flexbox m-top-10 font-size-small" },
                    [
                      _c("p", { staticClass: "c-success font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("Yield.Form_Label_KgQuantity"))),
                      ]),
                      _c(
                        "p",
                        { staticClass: "m-left-10 font-weight-normal c-dark" },
                        [_vm._v(_vm._s(_vm.kgQuantity))]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("unitId"),
                      expression: "veeErrors.has('unitId')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("unitId")))]
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.form.quantity,
                      expression: "form.quantity",
                      modifiers: { number: true },
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.validateQuantityRules,
                      expression: "validateQuantityRules",
                    },
                  ],
                  ref: "quantityInput",
                  staticClass: "txt no-spinners",
                  class: { "is-danger": _vm.veeErrors.has("quantity") },
                  attrs: {
                    name: "quantity",
                    id: "yield-detail-form-quantity",
                    autocomplete: "off",
                    "data-vv-as": _vm.$t("Dictionary.Quantity"),
                    type: "number",
                    step: "any",
                  },
                  domProps: { value: _vm.form.quantity },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "quantity",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("quantity"),
                      expression: "veeErrors.has('quantity')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("quantity")))]
              ),
            ]),
            _c("div", { staticClass: "flexbox m-top-10 font-size-small" }, [
              _vm.kgQuantity && _vm.form.quantity
                ? _c("div", { staticClass: "flexbox" }, [
                    _c("p", { staticClass: "c-success font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Yield.Form_Label_KgConvertion"))),
                    ]),
                    _c(
                      "p",
                      { staticClass: "m-left-10 font-weight-normal c-dark" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.kgQuantity *
                              _vm.form.quantity *
                              _vm.getfilteredUnitListItem.baseQuantity
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.form.stockItem
                ? _c("div", { staticClass: "flexbox m-left-10" }, [
                    _c("p", { staticClass: "c-success font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$t("Yield.StockOnHand"))),
                    ]),
                    _c(
                      "p",
                      { staticClass: "font-weight-normal c-dark m-left-10" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.form.stockOnHandQuantity ||
                              _vm.stockItemInfo.baseUnitQuantity
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "Form-item required m-top-15",
                attrs: { id: "popup-form-min-opt-item" },
              },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Yield.header-popup-cost-selection"))),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "control flexbox wrap m-top-10" },
                  [
                    _c(
                      "div",
                      { staticClass: "cost-selection-radio" },
                      [
                        _c("CustomRadio", {
                          attrs: {
                            id: `yield-header-popup-calculate-by-main-product`,
                            label: _vm.$t(
                              "Yield.header-popup-calculate-by-main-product"
                            ),
                            checked: _vm.form.pricingType === 1,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changePriceType(1)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cost-selection-radio" },
                      [
                        _c("CustomRadio", {
                          staticClass: "m-top-10",
                          attrs: {
                            id: `yield-header-popup-use-last-purchase-price`,
                            label: _vm.$t(
                              "Yield.header-popup-use-last-purchase-price"
                            ),
                            checked: _vm.form.pricingType === 2,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changePriceType(2)
                            },
                          },
                        }),
                        _c("customNumberInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: _vm.form.pricingType === 2,
                                min_value: 0,
                              },
                              expression:
                                "{required: form.pricingType === 2, min_value: 0}",
                            },
                          ],
                          staticClass: "last-purchase-price",
                          class: {
                            "is-danger": _vm.veeErrors.has("lastPurchasePrice"),
                          },
                          attrs: {
                            name: "lastPurchasePrice",
                            id: "yield-detail-form-last-purchase-price",
                            "data-vv-as": _vm.$t(
                              "Yield.detail-popup-last-purchase-price"
                            ),
                            disabled: _vm.form.pricingType !== 2,
                          },
                          model: {
                            value: _vm.form.lastPurchasePrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "lastPurchasePrice", $$v)
                            },
                            expression: "form.lastPurchasePrice",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("lastPurchasePrice"),
                          expression: "veeErrors.has('lastPurchasePrice')",
                        },
                      ],
                      attrs: {
                        errorName: _vm.veeErrors.first("lastPurchasePrice"),
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "cost-selection-radio" },
                      [
                        _c("CustomRadio", {
                          attrs: {
                            id: `yield-header-popup-calculate-by-main-product-percent`,
                            label: _vm.$t(
                              "Yield.header-popup-calculate-by-main-product-percent"
                            ),
                            checked: _vm.form.pricingType === 3,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changePriceType(3)
                            },
                          },
                        }),
                        _c("customNumberInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                required: _vm.form.pricingType === 3,
                                min_value: 0,
                              },
                              expression:
                                "{required: form.pricingType === 3, min_value: 0}",
                            },
                          ],
                          class: { "is-danger": _vm.veeErrors.has("percent") },
                          attrs: {
                            name: "percent",
                            id: "yield-detail-form-calculate-percent",
                            "data-vv-as": _vm.$t(
                              "Yield.detail-popup-calculate-by-main-product-percent"
                            ),
                            disabled: _vm.form.pricingType !== 3,
                            isTypePercentage: true,
                            placeholder: ",00",
                          },
                          model: {
                            value: _vm.form.pricingPercent,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pricingPercent", $$v)
                            },
                            expression: "form.pricingPercent",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("showValidateError", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.veeErrors.has("percent"),
                          expression: "veeErrors.has('percent')",
                        },
                      ],
                      attrs: { errorName: _vm.veeErrors.first("percent") },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "add-other-items-checkbox-container" },
            [
              !_vm.isEdit
                ? _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-inventory-header-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Global.detail"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-yield-add-detail",
                form: "yield-detail-form",
                disabled: _vm.isCreating,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t("Yield.Add_Yield_Detail"),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t("Yield.Add_Yield_Detail"),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCreating,
                    expression: "isCreating",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }