export function getYieldHeaderFields($t) {
  return [
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.DocNo')
      },
      get title() {
        return $t('Dictionary.DocNo')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Store')
      },
      get title() {
        return $t('Dictionary.Store')
      }
    },
    {
      name: 'yieldDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Date')
      },
      get title() {
        return $t('Dictionary.Date')
      }
    },
    {
      name: 'yieldStockItemName',
      is_visible: true,
      get short_title() {
        return $t('Yield.StockItemName')
      },
      get title() {
        return $t('Yield.StockItemName')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.StockOnHand')
      },
      get title() {
        return $t('Yield.StockOnHand')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    {
      name: 'price',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.amount')
      },
      get title() {
        return $t('Global.amount')
      }
    },
    {
      name: 'kgQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.kgQuantity')
      },
      get title() {
        return $t('Yield.kgQuantity')
      }
    },
    {
      name: 'wasteGroupName',
      type: 'supplier',
      is_visible: true,
      get short_title() {
        return $t('Yield.wasteGroupName')
      },
      get title() {
        return $t('Yield.wasteGroupName')
      }
    },
    {
      name: 'wasteQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.wasteQuantity')
      },
      get title() {
        return $t('Yield.wasteQuantity')
      }
    },
    {
      name: 'wastePercent',
      type: 'percent',
      is_visible: true,
      get short_title() {
        return $t('Yield.wastePercent')
      },
      get title() {
        return $t('Yield.wastePercent')
      }
    },
    {
      name: 'totalPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.total_amount')
      },
      get title() {
        return $t('Global.total_amount')
      }
    }
  ]
}

export function getYieldDetailFields($t) {
  return [
    {
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Yield.StockItemNameDetail')
      },
      get title() {
        return $t('Yield.StockItemNameDetail')
      }
    },
    {
      name: 'unitName',
      is_visible: true,
      get short_title() {
        return $t('Yield.unitName')
      },
      get title() {
        return $t('Yield.unitName')
      }
    },
    {
      name: 'stockItemKgQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.stockItemKgQuantity')
      },
      get title() {
        return $t('Yield.stockItemKgQuantity')
      }
    },
    {
      name: 'stockOnHandQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.StockOnHand')
      },
      get title() {
        return $t('Yield.StockOnHand')
      }
    },
    {
      name: 'quantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Dictionary.Quantity')
      },
      get title() {
        return $t('Dictionary.Quantity')
      }
    },
    {
      name: 'kgQuantity',
      type: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Yield.kgQuantity')
      },
      get title() {
        return $t('Yield.kgQuantity')
      }
    },
    {
      name: 'kgQuantityPercent',
      type: 'percent',
      is_visible: true,
      get short_title() {
        return $t('Yield.pricingPercent')
      },
      get title() {
        return $t('Yield.pricingPercent')
      }
    },
    {
      name: 'price',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.amount')
      },
      get title() {
        return $t('Global.amount')
      }
    },
    {
      name: 'totalPrice',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Global.total_amount')
      },
      get title() {
        return $t('Global.total_amount')
      }
    }
  ]
}
