export function getYieldBatchHeaderFields($t) {
  return [
    {
      name: 'yieldStockItemName',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_stockItemName')
      },
      get title() {
        return $t('Yield.batch_stockItemName')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_storeName')
      },
      get title() {
        return $t('Yield.batch_storeName')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_unitCode')
      },
      get title() {
        return $t('Yield.batch_unitCode')
      }
    },

    {
      name: 'shelfLife',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_shelfLife')
      },
      get title() {
        return $t('Yield.batch_shelfLife')
      },
    },
    {
      name: 'quantity',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_quantity')
      },
      get title() {
        return $t('Yield.batch_quantity')
      }
    },
    {
      name: 'remainingQuantity',
      type: 'currency',
      is_visible: true,
      get short_title() {
        return $t('Yield.batch_remainingQuantity')
      },
      get title() {
        return $t('Yield.batch_remainingQuantity')
      }
    },
    
  ]
}

export function getYieldBatchDetailFields($t) {
  return [
    {
      name: 'quantity',
      is_visible: true,
      type: 'mono',
      get short_title() {
        return $t('Yield.quantity')
      },
      get title() {
        return $t('Yield.quantity')
      }
    },
    {
      name: 'givenQuantity',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Yield.givenQuantity')
      },
      get title() {
        return $t('Yield.givenQuantity')
      }
    },
    {
      name: 'expirationDate',
      type: 'date',
      is_visible: true,
      get short_title() {
        return $t('Yield.expirationDate')
      },
      get title() {
        return $t('Yield.expirationDate')
      }
    },
    {
      name: 'degree',
      is_visible: true,
      get short_title() {
        return $t('Yield.degree')
      },
      get title() {
        return $t('Yield.degree')
      }
    },

    {
      name: 'warehouseTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Yield.warehouseTime')
      },
      get title() {
        return $t('Yield.warehouseTime')
      }
    },
    {
      name: 'shipmentTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Yield.shipmentTime')
      },
      get title() {
        return $t('Yield.shipmentTime')
      }
    },
    {
      name: 'lotNumber',
      is_visible: true,
      get short_title() {
        return $t('Yield.lotNumber')
      },
      get title() {
        return $t('Yield.lotNumber')
      }
    },
    {
      name: 'shelfStorageLocationListHeaderName',
      type: 'mono',
      is_visible: true,
      get short_title() {
        return $t('Yield.shelfStorageLocationListHeaderName')
      },
      get title() {
        return $t('Yield.shelfStorageLocationListHeaderName')
      }
    }
  ]
}

