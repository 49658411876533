import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import requestHandler from '@/utils/requestHandler'
import Yields from '../model'
import BatchList from '../model/yield-batch'
import BatchHeaderList from '../model/yield-batch-header'

export default {
  namespaced: true,
  state: {
    Yield: new Yields(),
    Page: {
      ...Page
    },
    batchList: new BatchList(),
    batchHeaderList: new BatchHeaderList()
  },
  getters: {
    Page: state => PageGetters({ state, stateName: 'Page' }),
    Yields: state => state.Yield.list,
    isFetchDataEmpty: state => state.Yield.list.length === 0,
    BatchList: state => state.batchList.list,
    BatchHeaderList: state => state.batchHeaderList.list
  },
  actions: {
    async fetchYieldHeaderAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        ...payload,
        pageNumber: PageNumber
      }
      const results = await requestHandler({
        title: 'fetchYieldHeaderAll',
        methodName: 'fetchYieldHeaderAll',
        method: () => {
          return HTTP.post('Yield/header/all', params)
        },
        success: result => {
          commit('UPDATE_LIST', result.data)
          return result
        }
      })
      return results
    },
    async fetchYield({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchYield',
        methodName: 'fetchYield',
        method: () => {
          return HTTP.get('Yield', {
            params: { Id }
          })
        },
        success: result => {
          commit('UPDATE_YIELD_ITEM', result.data.yieldHeader)
          return result
        }
      })
      return results
    },
    async createYieldHeader({ commit }, payload) {
      const results = await requestHandler({
        title: 'createYieldHeader',
        methodName: 'createYieldHeader',
        method: () => {
          return HTTP.post('Yield/header', payload)
        },
        success: result => result
      })
      return results
    },
    async updateYieldHeader({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateYieldHeader',
        methodName: 'updateYieldHeader',
        method: () => {
          return HTTP.put('Yield/header', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteYieldHeader({commit, dispatch}, Id) {
      let results = await requestHandler({
        title: 'deleteYieldHeader',
        methodName: 'deleteYieldHeader',
        method: () => {
          return HTTP.delete('Yield', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async fetchYieldDetailAll({ commit }, YieldHeaderId) {
      const results = await requestHandler({
        title: 'fetchYieldDetailAll',
        methodName: 'fetchYieldDetailAll',
        method: () => {
          return HTTP.get('Yield/detail/all', {
            params: { YieldHeaderId }
          })
        },
        success: result => {
          commit('SHOW_DETAIL', { YieldHeaderId: YieldHeaderId, resultDetail: result.data })
          return result
        }
      })
      return results
    },
    async getYieldDocumentDetail({ commit }, YieldHeaderId) {
      const results = await requestHandler({
        title: 'getYieldDocumentDetail',
        methodName: 'getYieldDocumentDetail',
        method: () => {
          return HTTP.get('Yield/detail/all', {
            params: { YieldHeaderId }
          })
        },
        success: result => result
      })
      return results
    },
    async fetchYieldDetail({ commit }, Id) {
      const results = await requestHandler({
        title: 'fetchYieldDetail',
        methodName: 'fetchYieldDetail',
        method: () => {
          return HTTP.get('Yield/detail', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },
    async createYieldDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'createYieldDetail',
        methodName: 'createYieldDetail',
        method: () => {
          return HTTP.post('Yield/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async updateYieldDetail({ dispatch, commit }, payload) {
      const results = await requestHandler({
        title: 'updateYieldDetail',
        methodName: 'updateYieldDetail',
        method: () => {
          return HTTP.put('Yield/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteYieldDetail({ commit }, Id) {
      let results = await requestHandler({
        title: 'deleteYieldDetail',
        methodName: 'deleteYieldDetail',
        method: () => {
          return HTTP.delete('Yield/detail', {
            params: { Id }
          })
        },
        success: result => result
      })

      return results
    },
    async finalizeYield({ commit }, payload) {
      let results = await requestHandler({
        title: 'finalizeYield',
        methodName: 'finalizeYield',
        method: () => {
          return HTTP.put('Yield/finalize', payload)
        },
        success: result => result
      })

      return results
    },


    async getBatchYieldHeaderData({ commit }, payload) {
      let results = await requestHandler({
        title: 'getBatchYieldHeaderData',
        methodName: 'getBatchYieldHeaderData',
        method: () => {
          return HTTP.post('Yield/header/batch', payload)
        },
        success: result => {
          commit('RESET_BATCH_HEADER_LIST')
          commit('SET_BATCH_HEADER_LIST', result.data)
          return result
        }
      })
      return results
    },

    async validateBatchHeader({ commit }, payload) {
      let results = await requestHandler({
        title: 'validateBatchHeader',
        methodName: 'validateBatchHeader',
        method: () => {
          return HTTP.post('Yield/header/batch/validate', payload)
        },
        success: result => result
      })
      return results
    },

    async fetchBatchYieldHeaderAll({ commit }, payload) {
      const results = await requestHandler({
        title: 'fetchBatchYieldHeaderAll',
        methodName: 'fetchBatchYieldHeaderAll',
        method: () => {
          return HTTP.post('Batch/yield/header/all', payload)
        },
        success: result => result
      })
      return results
    },
    
    async updateBatchHeaderQuantity({ commit }, payload) {
      let results = await requestHandler({
        title: 'updateBatchHeaderQuantity',
        methodName: 'updateBatchHeaderQuantity',
        method: () => {
          return HTTP.put('Batch/yield/header/quantity', payload)
        },
        success: result => result
      })
      return results
    },

    // Batch Detail 

    async getExpireDateData({ commit }, payload) {
      const results = await requestHandler({
        title: 'getExpireDateData',
        methodName: 'getExpireDateData',
        method: () => {
          return HTTP.post('Yield/detail/batch', payload)
        },
        success: result => {
          commit('UPDATE_BATCH_LIST', result.data)
          return result
        }
      })
      return results
    },

    async createBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'createBatch',
        methodName: 'createBatch',
        method: () => {
          return HTTP.post('Batch/yield', payload)
        },
        success: result => result
      })
      return results
    },

    async updateBatch({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'updateBatch',
        methodName: 'updateBatch',
        method: () => {
          return HTTP.put('Batch', payload)
        },
        success: result => result
      })
      return results
    },

    async validateBatchQuantity({ state, commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'validateBatchQuantity',
        methodName: 'validateBatchQuantity',
        method: () => {
          return HTTP.post('Yield/detail/batch/validate', payload)
        },
        success: result => result
      })
      return results
    },

    async getBatchDetails({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getBatchDetails',
        methodName: 'getBatchDetails',
        method: () => {
          return HTTP.post('Batch/yield/all', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteBatch({ state, commit, dispatch }, Id) {
      const results = await requestHandler({
        title: 'deleteBatch',
        methodName: 'deleteBatch',
        method: () => {
          return HTTP.delete('Batch', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    }


  },
  mutations: {
    UPDATE_LIST(state, data) {
      state.Yield.addItems(data.yieldHeaderList)
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },

    UPDATE_YIELD_ITEM(state, item) {
      state.Yield.updateItem(item)
    },
    //  DETAIL STATUS MUTATION
    HIDE_DETAIL(state, yieldHeader) {
      yieldHeader.showDetail = false
    },

    SHOW_DETAIL(state, { YieldHeaderId, resultDetail }) {
      let yieldHeaderList = state.Yield.findItem(YieldHeaderId)
      if (yieldHeaderList && resultDetail) {
        yieldHeaderList.addDetails(resultDetail.yieldDetailList.map(item => {
          return {
            ...item
          }
        }))
      }
      if (!yieldHeaderList.showDetail) yieldHeaderList.showDetail = true
    },
    UPDATE_BATCH_LIST(state, data) {
      state.batchList.addItems(data.yieldDetailList)
    },
    SHOW_BATCH_DETAIL(state, data) {
      let batchList = state.batchList.findItem(data.id)
      batchList.showDetail = true
      batchList.addDetails(data.batch.batchList)
    },
    HIDE_BATCH_DETAIL(state, batchList) {
      batchList.showDetail = false
    },
    // Header Batch
    SET_BATCH_HEADER_LIST(state, data) {
      state.batchHeaderList.addItem(data.yieldHeader)
    },
    RESET_BATCH_HEADER_LIST(state, data) {
      state.batchHeaderList = new BatchHeaderList()
    },
    HIDE_BATCH_HEADER_DETAIL(state, batchList) {
      batchList.showDetail = false
    },
    SHOW_BATCH_HEADER_DETAIL(state, data) {
      let batchHeaderList = state.batchHeaderList.findItem(data.id)
      batchHeaderList.showDetail = true
      batchHeaderList.addDetails(data.batchList)
    }
  }
}
