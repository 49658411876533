var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Global.editData", {
                        field: _vm.$t("Yield.header_add"),
                      })
                    )
                ),
              ]
            : [_vm._v(_vm._s(_vm.$t("Yield.yield_header_form_title")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        !_vm.isEdit
          ? _c(
              "div",
              { staticClass: "selectType" },
              [
                _c("SelectedButtons", {
                  attrs: { data: _vm.requestTypeOptions() },
                  on: { input: _vm.resetForm },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "yield-header-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Store"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("custom-search", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1|verify_inventory",
                        expression: "'required|is_not:-1|verify_inventory'",
                      },
                    ],
                    attrs: {
                      "component-name": "yield-header-list-stor",
                      name: "storeName",
                      "data-vv-as": _vm.$t("Dictionary.Store"),
                      inputClass: {
                        "is-danger":
                          _vm.inventoryValidation &&
                          _vm.veeErrors.has("storeName"),
                      },
                      "on-search": _vm.getOptionsStore,
                      options: _vm.selectMiniStoreList,
                      label: "name",
                      loading: _vm.isSearchStoreLists,
                      searchAll: true,
                      withDebounce: false,
                    },
                    on: {
                      input: _vm.inputSelection,
                      resetOptions: _vm.resetOptions,
                    },
                    model: {
                      value: _vm.form.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "storeId", $$v)
                      },
                      expression: "form.storeId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.inventoryValidation,
                        expression: "inventoryValidation",
                      },
                    ],
                    attrs: { errorName: _vm.validationErrorMessage },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Date"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("CustomDatepicker", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|verify_inventory",
                        expression: "'required|verify_inventory'",
                      },
                    ],
                    class: { "is-danger": _vm.veeErrors.has(`yieldDate`) },
                    attrs: {
                      id: "yield-header-form-datepicker",
                      name: "yieldDate",
                      "data-vv-as": _vm.$t("Dictionary.Date"),
                      pickerType: "manuel",
                      inputIconRight: "icon-datepicker-big",
                      inputIcon: "",
                      disabledStartDate: _vm.disableDate,
                    },
                    model: {
                      value: _vm.form.yieldDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "yieldDate", $$v)
                      },
                      expression: "form.yieldDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.veeErrors.has("yieldDate"),
                      expression: "veeErrors.has('yieldDate')",
                    },
                  ],
                  staticClass: "Form-item-help is-danger",
                },
                [_vm._v(_vm._s(_vm.veeErrors.first("yieldDate")))]
              ),
            ]),
            _vm.type === 0
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Yield.Form_StockItemName"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("custom-search", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|verify_inventory",
                            expression: "'required|verify_inventory'",
                          },
                        ],
                        ref: "focusableInput",
                        attrs: {
                          name: "stockItem",
                          "component-name": "form-yield-detail-stock-item",
                          "data-vv-as": _vm.$t("Yield.Form_StockItemName"),
                          inputClass: { "is-danger": _vm.inventoryValidation },
                          "on-search": _vm.getOptions,
                          options: _vm.selectOptions,
                          label: "name",
                          placeholder: _vm.$t(
                            "Yield.Form_StockItemName_Placeholder"
                          ),
                          disabled: _vm.isEdit,
                        },
                        on: { input: _vm.fetchStockItems },
                        model: {
                          value: _vm.form.stockItem,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "stockItem", $$v)
                          },
                          expression: "form.stockItem",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inventoryValidation,
                          expression: "inventoryValidation",
                        },
                      ],
                      staticClass: "Form-item-help is-danger",
                    },
                    [_vm._v(_vm._s(_vm.validationErrorMessage))]
                  ),
                ])
              : _vm._e(),
            _vm.type === 1
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Yield.Form_List"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c("custom-search", {
                        class: { "is-danger": _vm.veeErrors.has("list") },
                        attrs: {
                          name: "list",
                          "component-name": "yield-form-list",
                          "data-vv-as": _vm.$t("Yield.Form_List"),
                          "on-search": _vm.getOptionsYieldList,
                          searchAll: true,
                          options: _vm.selectOptionsYList,
                          label: "name",
                          placeholder: _vm.$t("Yield.Form_List"),
                          isAvailableCondition: "isAvailable",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.setListData()
                          },
                        },
                        model: {
                          value: _vm.form.list,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "list", $$v)
                          },
                          expression: "form.list",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.type === 0
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Yield.Form_BaseUnit"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.unitId,
                              expression: "form.unitId",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|is_not:-1",
                              expression: "'required|is_not:-1'",
                            },
                          ],
                          staticClass: "select",
                          class: { "is-danger": _vm.veeErrors.has("unitId") },
                          attrs: {
                            id: "select-yield-popup-baseunit",
                            name: "unitId",
                            disabled: !_vm.form.stockItem,
                            "data-vv-as": _vm.$t("Yield.Form_BaseUnit"),
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "unitId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _vm.isEdit
                            ? _c(
                                "option",
                                {
                                  attrs: {
                                    value: "-1",
                                    id: "option-yield-popup-baseunit-unselected",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("Yield.Form_BaseUnit_Unselected")
                                    )
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.filteredUnitList, function (unit) {
                            return _c(
                              "option",
                              {
                                key: unit.id,
                                attrs: {
                                  id: `option-yield-popup-baseunit-${unit.name}`,
                                },
                                domProps: { value: unit.id },
                              },
                              [_vm._v(_vm._s(unit.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Dictionary.Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: !_vm.allowNegativeStock
                          ? {
                              required: true,
                              greater_than: 0,
                              decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                              max_value: _vm.stockItemInfo
                                ? _vm.stockItemInfo.baseUnitQuantity
                                : _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            }
                          : {
                              required: true,
                              greater_than: 0,
                              decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                              max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                            },
                        expression:
                          "!allowNegativeStock ? {required: true, greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max_value: stockItemInfo ? stockItemInfo.baseUnitQuantity : $getConst('MAX_DIGITS_FOR_QUANTITY')}  : {required: true, greater_than:0, decimal: $getConst('DECIMAL_FOR_QUANTITY'), max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    attrs: {
                      id: "yield-detail-form-quantity",
                      name: "quantity",
                      "data-vv-as": _vm.$t("Dictionary.Quantity"),
                      error: _vm.veeErrors.has("quantity"),
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                  _vm.stockItemInfo.baseUnitQuantity
                    ? _c("div", { staticClass: "Form-item-info" }, [
                        _c("div", [
                          _c("div", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("Yield.StockOnHand"))),
                          ]),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(_vm._s(_vm.stockItemInfo.baseUnitQuantity)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "Form-item required",
                attrs: { id: "yield-cost-selection" },
              },
              [
                _c("label", { staticClass: "Form-item-label" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("Yield.header-popup-cost-selection"))),
                  ]),
                ]),
                _c("div", { staticClass: "control flexbox wrap m-top-10" }, [
                  _c(
                    "div",
                    { staticClass: "selection flexbox" },
                    [
                      _c("CustomRadio", {
                        attrs: {
                          id: `yield-header-popup-average-selection`,
                          label: _vm.$t("Yield.header-popup-average-selection"),
                          checked: _vm.form.pricingType === 1,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePricingType(1)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "selection flexbox" },
                    [
                      _c("CustomRadio", {
                        staticClass: "m-top-10",
                        attrs: {
                          id: `yield-header-popup-despatch-selection`,
                          label: _vm.$t(
                            "Yield.header-popup-despatch-selection"
                          ),
                          checked: _vm.form.pricingType === 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePricingType(2)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "selection flexbox" },
                    [
                      _c("CustomRadio", {
                        staticClass: "m-top-10",
                        attrs: {
                          id: `yield-header-popup-invoice-selection`,
                          label: _vm.$t("Yield.header-popup-invoice-selection"),
                          checked: _vm.form.pricingType === 3,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changePricingType(3)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.form.pricingType === 2 || _vm.form.pricingType === 3
                  ? _c(
                      "div",
                      { staticClass: "control m-top-15" },
                      [
                        _c("label", { staticClass: "Form-item-label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.form.pricingType === 2
                                ? _vm.$t("Yield.Form_Label_DespatchSelection")
                                : _vm.$t("Yield.Form_Label_InvoiceSelection")
                            )
                          ),
                        ]),
                        _c("custom-search", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            name: "pricing",
                            "component-name": "form-yield-pricing",
                            "data-vv-as":
                              _vm.form.pricingType === 2
                                ? _vm.$t("Yield.Form_Label_DespatchSelection")
                                : _vm.$t("Yield.Form_Label_InvoiceSelection"),
                            "on-search": _vm.getPricingSelectionOptions,
                            options: _vm.selectPricingSelectionOptions,
                            label:
                              _vm.form.pricingType === 2
                                ? "despatchNumber"
                                : "invoiceNumber",
                            placeholder:
                              _vm.form.pricingType === 2
                                ? _vm.$t("Yield.Form_Label_DespatchSelection")
                                : _vm.$t("Yield.Form_Label_InvoiceSelection"),
                            inputClass: {
                              "is-danger": _vm.veeErrors.has("pricing"),
                            },
                            disabled:
                              _vm.type === 0
                                ? !_vm.form.stockItem && !_vm.form.unitId
                                : !_vm.form.list,
                          },
                          model: {
                            value: _vm.form.pricing,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pricing", $$v)
                            },
                            expression: "form.pricing",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("pricing"),
                        expression: "veeErrors.has('pricing')",
                      },
                    ],
                    staticClass: "Form-item-help is-danger",
                  },
                  [_vm._v(_vm._s(_vm.veeErrors.first("pricing")))]
                ),
                _vm.form.pricing
                  ? _c(
                      "div",
                      { staticClass: "flexbox m-top-10 font-size-small" },
                      [
                        _c("p", { staticClass: "c-success font-weight-bold" }, [
                          _vm._v(_vm._s(_vm.$t("Yield.Form_Pricing_Price"))),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "m-left-10 font-weight-normal c-dark",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatCurrency")(_vm.form.pricing.price)
                              )
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
            _vm.type === 0
              ? _c("div", { staticClass: "Form-item required" }, [
                  _c("label", { staticClass: "Form-item-label" }, [
                    _vm._v(_vm._s(_vm.$t("Yield.waste_group"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "control form-item-select" },
                    [
                      _c("Icon", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isLoadingWasteGroup,
                            expression: "!isLoadingWasteGroup",
                          },
                        ],
                        staticClass: "icon-down-arrow",
                        attrs: { name: "icon-down-arrow" },
                      }),
                      _c("customSelectInput", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|is_not:-1",
                            expression: "'required|is_not:-1'",
                          },
                        ],
                        attrs: {
                          name: "wasteGroup",
                          optionData: _vm.WasteGroupList,
                          "data-vv-as": _vm.$t("Yield.waste_group"),
                          error: _vm.veeErrors.has("wasteGroup"),
                          id: "yield-header-select-waste-group",
                          optionIdName: "option-waste-popup-group-name",
                          disabled:
                            _vm.isLoadingWasteGroup || !_vm.form.storeId,
                        },
                        model: {
                          value: _vm.form.wasteGroupId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "wasteGroupId", $$v)
                          },
                          expression: "form.wasteGroupId",
                        },
                      }),
                      _c(
                        "Button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isLoadingWasteGroup,
                              expression: "isLoadingWasteGroup",
                            },
                          ],
                          attrs: {
                            variant: "icon loading",
                            id: "select-waste-from-waste-group-loading",
                            type: "button",
                            tabindex: "-1",
                          },
                        },
                        [_c("Loading", { attrs: { theme: "disable" } })],
                        1
                      ),
                      _c("showValidateError", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.veeErrors.has("wasteGroup"),
                            expression: "veeErrors.has('wasteGroup')",
                          },
                        ],
                        attrs: { errorName: _vm.veeErrors.first("wasteGroup") },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                id: "button-yield-add-header",
                form: "yield-header-form",
                disabled:
                  _vm.isCreating ||
                  _vm.inventoryValidation ||
                  _vm.isLoadingWasteGroup,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isCreating,
                      expression: "!isCreating",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.editData", {
                              field: _vm.$t("Yield.header_add"),
                            })
                          )
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Global.addData", {
                              field: _vm.$t("Yield.header_add"),
                            })
                          )
                        ),
                      ],
                ],
                2
              ),
              _c("Loading", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isCreating,
                    expression: "isCreating",
                  },
                ],
                attrs: { theme: "disable" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }