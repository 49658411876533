import Yield from '../index'
import YieldDocumentDetail from '../yield-detail-document'
import YieldHeaderForm from '../popup/yield-header-form'
import YieldDetailForm from '../popup/yield-detail-form'

import yieldHeaderDelete from '../popup/yield-header-delete'
import yieldDetailDelete from '../popup/yield-detail-delete'

export default [
  {
    name: 'Yield',
    component: Yield,
    path: '/yield',
    meta: {
      slug: 'yield',
      category: 'Cost',
      main_menu: true,
      page: 'yield',
      tabPermissionKey: 'YieldMenu'
    },
    children: [
      {
        name: 'NewYieldHeader',
        component: YieldHeaderForm,
        path: '/yield/new'
      },
      {
        name: 'EditYieldHeader',
        component: YieldHeaderForm,
        path: '/yield/edit/:headerId',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'deleteYieldHeader',
        component: yieldHeaderDelete,
        path: '/yield/remove/:id'
      },
      {
        name: 'NewYieldDetail',
        component: YieldDetailForm,
        path: '/yield/detail/:headerId'
      },
      {
        name: 'EditYieldDetail',
        component: YieldDetailForm,
        path: '/yield/edit/:headerId/:id',
        props: {
          status: 'edit'
        }
      },
      {
        name: 'deleteYieldDetail',
        component: yieldDetailDelete,
        path: '/yield/detail/remove/:id'
      }
    ]
  },
  {
    path: '/yield/detail/:id/:documentNumber',
    name: 'YieldDocumentDetail',
    component: YieldDocumentDetail,
    meta: {
      tabPermissionKey: 'YieldMenu',
      page: 'yield'
    }
  }
]
