export function getExpireDateFields($t) {
  return [
    { 
      name: 'stockItemName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_StockItemName')
      }
    },
    {
      name: 'storeName',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_StoreName')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_StoreName')
      }
    },
    {
      name: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'remainingQuantity',
      is_visible: true,
      get short_title() {
        return $t('Despatches.remainingQuantity')
      },
      get title() {
        return $t('Despatches.remainingQuantity')
      }
    },
    {
      name: 'unitCode',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_UnitCode')
      }
    }
  ]
}
  
export function getExpireDateDetailFields($t) {

  return [
    {
      name: 'discard',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ""
      },
      get title() {
        return ""
      }
    },
    {
      name: 'quantity',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      },
      get title() {
        return $t('Despatches.detail_TableColumn_Quantity')
      }
    },
    {
      name: 'shelfLife',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.shelfLife')
      },
      get title() {
        return $t('Despatches.shelfLife')
      }
    },
    {
      name: 'productionDate',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.productionDate')
      },
      get title() {
        return $t('Despatches.productionDate')
      }
    },
    {
      name: 'expirationDate',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('Despatches.expirationDate')
      },
      get title() {
        return $t('Despatches.expirationDate')
      }
    },
    {
      name: 'degree',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.degree')
      },
      get title() {
        return $t('Despatches.degree')
      }
    },
    {
      name: 'shipmentTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.shipmentTime')
      },
      get title() {
        return $t('Despatches.shipmentTime')
      }
    },
    {
      name: 'lotNumber',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.lotNumber')
      },
      get title() {
        return $t('Despatches.lotNumber')
      }
    },
    {
      name: 'warehouseTime',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.warehouseTime')
      },
      get title() {
        return $t('Despatches.warehouseTime')
      }
    },
    {
      name: 'shelfStorageLocationList',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Despatches.shelf')
      },
      get title() {
        return $t('Despatches.shelf')
      }
    },
    {
      name: 'saveBatch',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return ""
      },
      get title() {
        return ""
      }
    }
  ]
}